import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function RDDownloads() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Research and Development Cell" /> 
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                     

 

                    <br/> 
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Research Ordinance (Important Forms)</h3>
                                </div>

                                <table class="table table-bordered">
                                    <thead class="table-light head_red">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Document</th> 
                                            <th scope="col">Link to Download</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 

                                        <tr>
                                            <td>1</td>
                                            <td> Synopsis Format </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/MVN-synopsis-guidelines.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>2</td>
                                            <td> Progress Report </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Progress-Report-Format-for-Research-Scholar.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>3</td>
                                            <td> Research Proposal </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Research-proposal-format-for-MVNU_new.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>4</td>
                                            <td> Submission Report of Ph.D. </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Submission-report-of-PhD-document.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>5</td>
                                            <td> Font Page </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Department-details-for-front-page-in-synopsis-and-thesis.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>6</td>
                                            <td> Ph.D. Thesis submission Guidelines </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/PhD-Thesis-Submission-Guidelines-1-3-22.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>7</td>
                                            <td> Compliance Report </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Compliance-Report.docx`} download >Download </a> </td>
                                        </tr>


                                        <tr>
                                            <td>8</td>
                                            <td> Title Change Application </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Title Change Application.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>9</td>
                                            <td> Application for Extension </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Application-for-Extension.docx`} download >Download </a> </td>
                                        </tr>


                                        <tr>
                                            <td>10</td>
                                            <td> Annexure 1 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-1-Cover-page-NEW.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>11</td>
                                            <td> Annexure 2	 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-2-Certificate-by-Supervisor.docx`} download >Download </a> </td>
                                        </tr>


                                        <tr>
                                            <td>12</td>
                                            <td> Annexure 3 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure 3 Declaration by Scholar.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>13</td>
                                            <td> Annexure 4 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-4-Certificate-of-Plagiarism-check.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>14</td>
                                            <td> Annexure 5 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-5-Publication-Details.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>15</td>
                                            <td> Annexure 6</td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-6-Conference-Details.docx`} download >Download </a> </td>
                                        </tr>


                                        <tr>
                                            <td>16</td>
                                            <td> Annexure 7 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-7-No-due-Certificate.docx`} download >Download </a> </td>
                                        </tr>


                                       

                                         

                                        </tbody> 
 


                                 </table>   


                            </div>
                        </div>
                    </div>

                    <br/> 



 
 


                </div>
            </section>

    </>
  )
}

export default RDDownloads