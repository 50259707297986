import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../headerone/Main'
import { Link } from 'react-router-dom'
import First from './first'
import NewMain from '../../headerone/newMain'
function AcedamicBankCredit() {
 

  return (
    <>
      <NewMain />
      <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  {/* <h2> {image?"":subtitle} </h2> */}
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Home </Link>
                  </li> 
                  <li className="breadcrumb-item">
                    <Link to="/re-evalution-rules">Examination </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="container">
            <h2 class="text-center">  Academic Bank of Credits   </h2>
            <br/> 
            </div>
              
            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="text-justify">
                        In response to D.O.No.F.1-1/2022 (ABCID) dated 22nd September 2022, MVN University acknowledges the urgent implementation of the Academic Bank of Credits (ABC), as directed by the University Grants Commission (UGC). This aligns with the UGC's established regulations on the establishment and operation of ABC in higher education, as gazetted on 28th July 2021. Developed under the DigiLocker framework by the National e-Governance Division, Ministry of Electronics and Information Technology, the ABC platform facilitates the opening of Academic Accounts by students and the onboarding of Higher Educational Institutions (HEIs), including MVN University. <br/><br/>
The ABC at MVN University is a digital repository for academic credits, ensuring efficient degree/diploma/certificate award processes based on accumulated credits. Operating on the principles of multiple entry-multiple exit and emphasizing anytime, anywhere, and any level of learning, ABC empowers students at MVN University to navigate their unique learning paths. This transformative initiative enhances flexibility and accessibility in higher education, aligning with MVN's commitment to providing a dynamic and responsive educational experience. The implementation of ABC at MVN University reflects its dedication to staying at the forefront of educational innovation, fostering an environment where students take ownership of their learning experiences.

                        </p>


                        <br/> <br/>
                        <ul class="details_list">

                                <li><a href={`${process.env.PUBLIC_URL}/assets/pdf/1. Implementation of National Academic Depository.pdf`}  target="_blank" rel="noopener"> 1. Implementation of National Academic Depository</a></li> 
                                <li><a href={`${process.env.PUBLIC_URL}/assets/pdf/2. Implementation of ABC ID.pdf`}  target="_blank" rel="noopener"> 2. Implementation of ABC ID</a></li>
     
                                <li><a href={`${process.env.PUBLIC_URL}/assets/pdf/3. Notice for NAD Registeration.pdf`}  target="_blank" rel="noopener">3. Notice for NAD Registeration</a></li>


                                <li><a href={`${process.env.PUBLIC_URL}/assets/pdf/4. How to register.pdf`}  target="_blank" rel="noopener">4. How to register</a></li>
                                 
                                </ul>


                        
                </div>
            </div>
            </div>
        </section>
    </>
  )
}

export default AcedamicBankCredit