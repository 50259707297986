import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../headerone/Main'
import { Link } from 'react-router-dom'
import First from './first'
import NewMain from '../../headerone/newMain'
function ExaminationScheme() {
 /* const navigate=useNavigate()
    useEffect(() => { 
        const pdfUrl = `${process.env.PUBLIC_URL}/assets/img/new_images/examination_scheme.pdf`;
         
        window.open(pdfUrl, '_blank');
        navigate(-1)
      }, []);  */

  return (
    <>
      <NewMain />
      <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  {/* <h2> {image?"":subtitle} </h2> */}
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Home </Link>
                  </li> 
                  <li className="breadcrumb-item">
                    <Link to="/re-evalution-rules">Examination </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="container">
            <h2 class="text-center"> Examination Scheme (w.e.f. 2023-24)
            </h2>
            <br/> 
            </div>
                
            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="text-justify">
                        It is notified for the information of all concerned that, honorable Vice Chancellor, in anticipation of the approval of Academic Council, has approved the following examination scheme  <strong>(applicable for all batches from the academic year 2023-24 onwards except D. Pharm, B. Pharm, M. Pharm and BPT programs) </strong>
The examination scheme has three components, i.e., Mid-Term Examination, End-Term Examination, and Continuous Evaluation by the faculty. 


                        </p>

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">1.	Pattern of Mid Term Examination   </h3>
                          <p> The Mid-Term examination shall be of 30 marks covering first three units of the syllabus of the course. There shall be nine questions in total, three from each unit. It is compulsory to attempt at least one question from each unit. In total, students shall be required to attempt six questions.  Each question will carry five marks. Duration of the examination shall be 2 hours.   </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 2.	Pattern of End Term Examination </h3>
                          <p>  The End Term examination shall be of 50 marks covering the entire syllabus. There shall be 13 questions in total from the syllabus. There shall be two questions from each unit, and it is compulsory to attempt at least one question from each of the last three units. Each question will carry 5 marks. There shall be one compulsory question of 10 marks from the last three units. It will consist of 10 MCQs/True-False/Fill in the Blanks/Very Short Answer Type questions of one mark each. In total, students shall be required to attempt nine questions. Duration of the examination shall be 3:00 hours. </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 3.	Continuous Evaluation </h3>
                          <p>   </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">  </h3>
                          <p> There shall be continuous evaluation by the faculty of 20 marks for class performance, attendance, assignment, and innovative activities relating sustainability goals etc.  Out of 20, 10 marks will be allocated for group project/assignment/seminar/practical files (as applicable).  <br></br>
The twenty marks of continuous evaluation for theory subjects shall be awarded on the following basis: 
  </p>


                        <ul>
                            <li>(i)	Attendance and Innovative Activities relating sustainability goals		         	(10 Marks)</li>
                            <li>(ii)	Group Projects, Assignment, Seminar, class presentation etc. (as applicable)	(10 Marks)</li>
                        </ul>

                        <p>The twenty marks of continuous evaluation for practical subjects shall be awarded on the following basis:</p>
                        <ul>
                            <li>(i)	Practical Files  							(10 marks)</li>
                            <li>(ii)	Attendance and Day to Day Performance in Practical Class	(10 Marks)</li>
                        </ul>



                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">4.	Minimum Marks for Passing the Examination  </h3>
                          <p>  A student shall be declared to have passed the examinations of a particular semester/year only if he/she secures at least 4.00 SGPA in each of the subjects separately in the theory as well as the practical examinations, including marks of Mid Term Examinations and Continuous Evaluation.  </p>
                          <ul>
                            <li></li>
                            <li></li>
                        </ul>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 5.	Re-Mid Term Examination </h3>
                          <p> In case of absence or failure in Mid Term Exams (theory or practical), a student may apply for a re-examination of Mid Term before End Term Examinations. The fee for the Re- Mid Term Examination shall be Rs. 500 per paper.  </p>
                          
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 6.	Reappearing End-Term Theory Examinations </h3>
                          <p>  In case a student is re-appearing in some theory examination (s), the marks earlier secured by him/her in Theory Mid Term Examinations and Continuous Evaluation shall be carried forward. Re-appearing End Term Theory Examination shall be conducted for 50 marks from the entire syllabus. The pattern of re-appearing examination shall be the same as that of regular End Term Examinations. In case a student did not pass after re-appearing examinations (Mid Term & End Term both), he or she may be allowed to reappear in the subsequent semester(s). The fee for the reappearing End Term Theory Examinations will be as per MVNU rules. </p>
                           
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 7.	Practical Examinations </h3>
                          <p> The pattern of practical examinations shall be the same as that of theory examinations. Mid Term Examination of practical subjects shall be conducted by internal faculty. End Term Practical Examinations must be completed before the commencement of the End Term Theory Examinations.   </p>
                           
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 8.	Reappearing Practical Examinations </h3>
                          <p>  In case a student is re-appearing in End Term Practical Examination(s), the marks earlier secured by him/her in Practical Mid Term Examinations and Continuous Evaluation shall be carried forward. Re-appearing practical examinations shall be conducted in the subsequent semester(s). The fee for the reappearing End Term Practical Examinations will be as per MVNU rules. </p>
                          
                        </div> 
 

                        
                </div>
            </div>
            </div>



            <div className="container">
            <h2 class="text-center"> Examination Scheme for Bachelor in Physiotherapy Program (BPT)
            </h2>
            <br/> 

            <p>The examination scheme for each theory and practical subject of the BPT program is as per the Haryana Council for Physiotherapy. The scheme is as under: <br/> <br/>
Internal Examination (Sessional Examination)- 20 Marks<br/> 
External Examination (End Term Examination)-80 Marks<br/> 
Total Weightage- 100 Marks<br/> 
</p>
            </div>

            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                    <h2 class="text-center"> Scheme of Sessional Examination
                    </h2>

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">Scheme of Sessional Examination- I   </h3>
                          <p>  Sessional Examination- I is conducted for the first two units. Duration of the exam is one hour. Weightage of the examination is twenty marks. There are six questions (three questions from each unit) in examination. Students are required to attempt any four questions. Weightage of each question is five marks. </p>
                           
                        </div> 

                         

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> Scheme of Sessional Examination- II  </h3>
                          <p>   Sessional Examination- II is conducted for the third and fourth units. Duration of the exam is one hour. Weightage of the examination is twenty marks. There are six questions (three questions from each unit) in examination. Students are required to attempt any four questions. Weightage of each question is five marks.</p>  <br/>

                          <p> <strong>Note: </strong>Average of the marks obtained in Sessional Exams-I and II is considered for the internal examination marks.</p>
                          


                          
                        </div> 


                        <div class="mvn_imse_omse">
                        <h2 class="text-center"> Scheme of External Examination (End Term Examination)
                        </h2>

                          
                          <p>  External Examination (End Term Examination) is conducted for the complete syllabus at the end of the academic year. Weightage of the examination is eighty marks. Duration of the examination is three hours. <br/>
                            
                            There are two sections in the examination.
 </p>
                          
                          
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> Section-A  </h3>
                          <p> This section covers the entire syllabus. It is compulsory to attempt this section. Weightage of this section is twenty marks.  </p>
                           
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">Section- B   </h3>
                          <p> This section covers the entire syllabus. There are four questions (one question from each unit) from
the first four units and two questions from each of the fifth and sixth units. Each question is of ten
marks. Students are required to attempt any six questions. 
  </p>
                           
                        </div> 



                        <div class="mvn_imse_omse">
                        <h2 class="text-center"> Course Structure, Scheme of Examination and Duration of the Coursework for the PhD Programme </h2>

                          
                          <p>  	<strong>1. Duration of the Course Work:</strong> The duration of the coursework will be one semester. A candidate who did not pass or secure 6.00 CGPA will be allowed to reappear in the Mid-Term or End-Term or both examinations within six months after the declaration of the result. If a candidate still does not pass the PhD coursework examination with a minimum 6.0 CGPA, he/she has to resume coursework as a fresh admission.
 </p>
                          

 <p><strong>2.  Course Structure of the Course Work: </strong> The credit requirement for the coursework shall be 12 credits. It is mandatory to offer one course on Research Methodology for four credits and another on Research and Publication Ethics for two credits. The course structure of the coursework for the PhD programme is as follows:
 </p>
                          


          <table  class="table table-bordered">
            <tr>
              <th>Name of the Course</th>
              <th>Credits</th>
              <th>Duration of the Exam</th> 
            </tr>

            <tr>
              <td>Research Methodology</td>
              <td>4 credits</td>
              <td>03 Hours</td> 
            </tr>

            <tr>
              <td>Research and Publication Ethics </td>
              <td>2 credits  </td>
              <td>02 Hours </td> 
            </tr>

            <tr>
              <td>Department Specialization Subject-I </td>
              <td>  3 credits</td>
              <td> 03 Hours</td> 
            </tr>

            <tr>
              <td>Department Specialization Subject-II </td>
              <td>3 credits  </td>
              <td>03 Hours </td> 
            </tr>

            <tr>
              <td><strong> Total Credits </strong> </td>
              <td><strong>12 </strong> </td>
              <td> </td> 
            </tr>
          </table>




                         
 <p><strong>3.	Scheme of Examination: </strong> A candidate will be allowed to appear in the coursework examination if he/she has attended 75% of the lectures delivered in the coursework. Each course shall have an internal assessment of 20% marks. It will comprise one written assignment and one presentation of 10% marks each. The concerned research supervisor/Head of the department will maintain the record on the basis of which internal assessment has been awarded for at least six months after the declaration of the result of course work. Each candidate will be examined in the courses as per the following examination scheme: 
 </p>
                          


          <table  class="table table-bordered">
            <tr>
              <th>Examination</th>
              <th>Weightage of Marks</th>
              
            </tr>

            <tr>
              <td>Internal Assessment</td>
              <td>20%</td> 
            </tr>

            <tr>
              <td>Mid Term Examination </td>
              <td>30%</td> 
            </tr>

            <tr>
              <td>End Term Examination </td>
              <td>  50%</td> 
            </tr>

             
          </table>

          <p>Mid-Term and End-Term examinations shall be conducted as per the new examination scheme-2023 of MVN University. A candidate shall be required to score a minimum 6.0 CGPA to be eligible for the registration in the PhD programme. In case a candidate fails, marks of the internal assessment and Mid-Term examinations shall be carried forward to the next examination.		</p>




                        </div> 




                        
                </div>
            </div>
            </div>




        </section>
    </>
  )
}

export default ExaminationScheme