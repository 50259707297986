import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function ListofPhResearchScholars() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Ph.D. Students: List of Ph.D. Students" />
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                      
                    <br/> 
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Ph.D. Students: List of Ph.D. Students</h3>
                                </div>

                                <table class="table table-bordered">
                                    <thead class="table-light head_red">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">School</th> 
                                            <th scope="col">BATCH</th> 
                                            <th scope="col">Student Name</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 

                                    <tr>
                                          <td>1</td>
                                          <td>SOL</td>
                                          <td>2017-18</td>
                                          <td>Brajendra Singh Kushwaha</td>
                                      </tr>
                                      <tr>
                                          <td>2</td>
                                          <td>SOL</td>
                                          <td>2017-18</td>
                                          <td>Chandranshu Pateria</td>
                                      </tr>
                                      <tr>
                                          <td>3</td>
                                          <td>SOL</td>
                                          <td>2017-18</td>
                                          <td>Laxhman Rohit Dubey</td>
                                      </tr>
                                      <tr>
                                          <td>4</td>
                                          <td>SOL</td>
                                          <td>2017-18</td>
                                          <td>Sapna Verma</td>
                                      </tr>
                                      <tr>
                                          <td>5</td>
                                          <td>SOET</td>
                                          <td>2017-18</td>
                                          <td>Mamta Saxena</td>
                                      </tr>
                                      <tr>
                                          <td>6</td>
                                          <td>SOPS</td>
                                          <td>2018-19</td>
                                          <td>Nikhleshwar Kumar Rana</td>
                                      </tr>
                                      <tr>
                                          <td>7</td>
                                          <td>SOET</td>
                                          <td>2019-20</td>
                                          <td>Babita Yadav</td>
                                      </tr>
                                      <tr>
                                          <td>8</td>
                                          <td>SOL</td>
                                          <td>2019-20</td>
                                          <td>Monika Chawla</td>
                                      </tr>
                                      <tr>
                                          <td>9</td>
                                          <td>SOL</td>
                                          <td>2019-20</td>
                                          <td>Ravi Chadha</td>
                                      </tr>
                                      <tr>
                                          <td>10</td>
                                          <td>SOL</td>
                                          <td>2019-20</td>
                                          <td>Sanjay Aggarwal</td>
                                      </tr>
                                      <tr>
                                          <td>11</td>
                                          <td>SOL</td>
                                          <td>2019-20</td>
                                          <td>Varun Kalra</td>
                                      </tr>
                                      <tr>
                                          <td>12</td>
                                          <td>SOL</td>
                                          <td>2019-20</td>
                                          <td>Ishwar Kumari</td>
                                      </tr>
                                      <tr>
                                          <td>13</td>
                                          <td>SOPS</td>
                                          <td>2019-20</td>
                                          <td>Km.&nbsp;Swati Srivastava</td>
                                      </tr>
                                      <tr>
                                          <td>14</td>
                                          <td>SOPS</td>
                                          <td>2019-20</td>
                                          <td>Vijeta Gupta</td>
                                      </tr>
                                      <tr>
                                          <td>15</td>
                                          <td>SOL</td>
                                          <td>2019-20</td>
                                          <td>Ritu</td>
                                      </tr>
                                      <tr>
                                          <td>16</td>
                                          <td>SOL</td>
                                          <td>2019-20</td>
                                          <td>Yash Pal Sharma</td>
                                      </tr>
                                      <tr>
                                          <td>17</td>
                                          <td>SOL</td>
                                          <td>2019-20</td>
                                          <td>Dinesh Mohan Sinha</td>
                                      </tr>
                                      <tr>
                                          <td>18</td>
                                          <td>SOET</td>
                                          <td>2020-21</td>
                                          <td>Poonam</td>
                                      </tr>
                                      <tr>
                                          <td>19</td>
                                          <td>SOET</td>
                                          <td>2020-21</td>
                                          <td>Atul Rana</td>
                                      </tr>
                                      <tr>
                                          <td>20</td>
                                          <td>SOET</td>
                                          <td>2020-21</td>
                                          <td>Sachin Garg</td>
                                      </tr>
                                      <tr>
                                          <td>21</td>
                                          <td>SOET</td>
                                          <td>2020-21</td>
                                          <td>Sudha Rani</td>
                                      </tr>
                                      <tr>
                                          <td>22</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Rachna</td>
                                      </tr>
                                      <tr>
                                          <td>23</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Karuna</td>
                                      </tr>
                                      <tr>
                                          <td>24</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Sanjeev Tiwari</td>
                                      </tr>
                                      <tr>
                                          <td>25</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Devesh Grover</td>
                                      </tr>
                                      <tr>
                                          <td>26</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Surbhi Bhardwaj</td>
                                      </tr>
                                      <tr>
                                          <td>27</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Nand Kishore Aggarwal</td>
                                      </tr>
                                      <tr>
                                          <td>28</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Parvinder</td>
                                      </tr>
                                      <tr>
                                          <td>29</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Rama</td>
                                      </tr>
                                      <tr>
                                          <td>30</td>
                                          <td>SOL</td>
                                          <td>2020-21</td>
                                          <td>Rachna / W21</td>
                                      </tr>
                                      <tr>
                                          <td>31</td>
                                          <td>SOPS</td>
                                          <td>2020-21</td>
                                          <td>Mahesh Kumar</td>
                                      </tr>
                                      <tr>
                                          <td>32</td>
                                          <td>SSH</td>
                                          <td>2020-21</td>
                                          <td>Ram Kumar</td>
                                      </tr>
                                      <tr>
                                          <td>33</td>
                                          <td>SSH</td>
                                          <td>2020-21</td>
                                          <td>Mamta Sharma</td>
                                      </tr>
                                      <tr>
                                          <td>34</td>
                                          <td>SSH</td>
                                          <td>2020-21</td>
                                          <td>Sangita Sheron</td>
                                      </tr>
                                      <tr>
                                          <td>35</td>
                                          <td>SAHS</td>
                                          <td>2020-21</td>
                                          <td>Mahesh Singh Danu</td>
                                      </tr>
                                      <tr>
                                          <td>36</td>
                                          <td>SSH</td>
                                          <td>2020-21</td>
                                          <td>Yogesh</td>
                                      </tr>
                                      <tr>
                                          <td>37</td>
                                          <td>SOET</td>
                                          <td>2021-22</td>
                                          <td>Sudha</td>
                                      </tr>
                                      <tr>
                                          <td>38</td>
                                          <td>SOET</td>
                                          <td>2021-22</td>
                                          <td>Amrita Koul</td>
                                      </tr>
                                      <tr>
                                          <td>39</td>
                                          <td>SOET</td>
                                          <td>2021-22</td>
                                          <td>Neetu Singh</td>
                                      </tr>
                                      <tr>
                                          <td>40</td>
                                          <td>SOL</td>
                                          <td>2021-22</td>
                                          <td>Prince Kumar</td>
                                      </tr>
                                      <tr>
                                          <td>41</td>
                                          <td>SOL</td>
                                          <td>2021-22</td>
                                          <td>Ram Kumar</td>
                                      </tr>
                                      <tr>
                                          <td>42</td>
                                          <td>SOL</td>
                                          <td>2021-22</td>
                                          <td>Chanchal Vashishtha</td>
                                      </tr>
                                      <tr>
                                          <td>43</td>
                                          <td>SOL</td>
                                          <td>2021-22</td>
                                          <td>Sushil Kumar</td>
                                      </tr>
                                      <tr>
                                          <td>44</td>
                                          <td>SOPS</td>
                                          <td>2021-22</td>
                                          <td>Bhawna Goel</td>
                                      </tr>
                                      <tr>
                                          <td>45</td>
                                          <td>SOPS</td>
                                          <td>2021-22</td>
                                          <td>Swati Dubey</td>
                                      </tr>
                                      <tr>
                                          <td>46</td>
                                          <td>SOET</td>
                                          <td>2021-22</td>
                                          <td>Amresh Kumar</td>
                                      </tr>
                                      <tr>
                                          <td>47</td>
                                          <td>SSH</td>
                                          <td>2021-22</td>
                                          <td>Vinnay Banga</td>
                                      </tr>
                                      <tr>
                                          <td>48</td>
                                          <td>SOET</td>
                                          <td>2022-23</td>
                                          <td>Anita</td>
                                      </tr>
                                      <tr>
                                          <td>49</td>
                                          <td>SOET</td>
                                          <td>2022-23</td>
                                          <td>Awnindra Dautt Tiwari</td>
                                      </tr>
                                      <tr>
                                          <td>50</td>
                                          <td>SOET</td>
                                          <td>2022-23</td>
                                          <td>Vishal Singh</td>
                                      </tr>
                                      <tr>
                                          <td>51</td>
                                          <td>SOET</td>
                                          <td>2022-23</td>
                                          <td>Vibhore Goel</td>
                                      </tr>
                                      <tr>
                                          <td>52</td>
                                          <td>SOET</td>
                                          <td>2022-23</td>
                                          <td>Ms Ruchika Gupta</td>
                                      </tr>
                                      <tr>
                                          <td>53</td>
                                          <td>SOET</td>
                                          <td>2022-23</td>
                                          <td>Ms Suman Devi</td>
                                      </tr>
                                      <tr>
                                          <td>54</td>
                                          <td>SOET</td>
                                          <td>2022-23</td>
                                          <td>Deepika Yadav</td>
                                      </tr>
                                      <tr>
                                          <td>55</td>
                                          <td>SSH</td>
                                          <td>2022-23</td>
                                          <td>Seema Sharma</td>
                                      </tr>
                                      <tr>
                                          <td>56</td>
                                          <td>SAHS</td>
                                          <td>2022-23</td>
                                          <td>Priya</td>
                                      </tr>
                                      <tr>
                                          <td>57</td>
                                          <td>SOET</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Rachana Srivastava</td>
                                      </tr>
                                      <tr>
                                          <td>58</td>
                                          <td>SOL</td>
                                          <td nowrap="">2023-24</td>
                                          <td nowrap="">Kanchan</td>
                                      </tr>
                                      <tr>
                                          <td>59</td>
                                          <td>SOET</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Munesh</td>
                                      </tr>
                                      <tr>
                                          <td>60</td>
                                          <td>SOL</td>
                                          <td nowrap="">2023-24</td>
                                          <td nowrap="">Ruchi Sharma</td>
                                      </tr>
                                      <tr>
                                          <td>61</td>
                                          <td>SOET</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Poonam</td>
                                      </tr>
                                      <tr>
                                          <td>62</td>
                                          <td>SOL</td>
                                          <td nowrap="">2023-24</td>
                                          <td>Ragini Agarwal</td>
                                      </tr>
                                      <tr>
                                          <td>63</td>
                                          <td>SOET</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Leena Rani</td>
                                      </tr>
                                      <tr>
                                          <td>64</td>
                                          <td>SOL</td>
                                          <td>2022-23</td>
                                          <td>Neeraj Mehta</td>
                                      </tr>
                                      <tr>
                                          <td>65</td>
                                          <td>SOL</td>
                                          <td>2022-23</td>
                                          <td>Vineet Chauhan</td>
                                      </tr>
                                      <tr>
                                          <td>66</td>
                                          <td>SOPS</td>
                                          <td>2022-23</td>
                                          <td>Neha Bhamboo</td>
                                      </tr>
                                      <tr>
                                          <td>67</td>
                                          <td>SOPS</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Shivani</td>
                                      </tr>
                                      <tr>
                                          <td>68</td>
                                          <td>SAHS</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Cheenu</td>
                                      </tr>
                                      <tr>
                                          <td>69</td>
                                          <td>SAHS</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Shivani Shokhanda</td>
                                      </tr>
                                      <tr>
                                          <td>70</td>
                                          <td>SOPS</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Rajeshwar Verma</td>
                                      </tr>
                                      <tr>
                                          <td>71</td>
                                          <td>SOPS</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Prabha Singh</td>
                                      </tr>
                                      <tr>
                                          <td>72</td>
                                          <td>SOPS</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Aashish Bharadwaj</td>
                                      </tr>
                                      <tr>
                                          <td>73</td>
                                          <td>SOPS</td>
                                          <td>2023-24</td>
                                          <td nowrap="">Anshuman Koul</td>
                                      </tr>

                                        </tbody> 
 


                                 </table>   


                            </div>
                        </div>
                    </div>

                     



 
 


                </div>
            </section>
    </>
  )
}

export default ListofPhResearchScholars