import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Container, Typography, Card, CardMedia, CardContent, Stack } from '@mui/material';
import { CustomTypography } from '../../utils/utils';
import MainTitle from './mainTitle';
import MUICard from '../muiCommonComponent/muiCard';
const CardStyle = {

  border: 0,
  minHeight: 90,
  margin: 'auto',
  textAlign: 'center',
  padding: '10px',
  backgroundColor: '#fff',
  height: '220px',
  width: '100%',
  marginTop: '50px',
  color: '#000',
  //   [theme.breakpoints.up('md')]: {
  //     boxShadow: 'none',      
  //     backgroundColor: '#fff',
  //     padding: 10,
  //   },
  '& img': {
    width: 'unset',
    margin: '0 auto'
  },

}
const AboutContent=()=>(
  <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={12} lg={12}>
    <video
      src="/video_banner.mp4"
      autoPlay
      loop
      muted
      poster="/video_cover.jpg"
      // style={{ height: '500px', width: '100%' }}
    >
      Your browser does not support the video tag.
    </video>
  </Grid>
  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ background: '#07123d', }}>
    <Stack sx={{ padding: '10px' }} spacing={2}>
      <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color="white">
      MVN University was established by Government of Haryana under section 2(f) of UGC Act 1956, and Approved by UGC, since then we have extended collaboration with reputed international universities and industries. MVN University seeks to transform higher education in India by creating a world-class infrastructure and an innovative teaching, learning, and research environment. Educationist at the MVN University are actively engaged in R&D across various fields, including agri-electronics resource management, renewable energy sensors, robotics, photonic crystals, biotechnology, allied health sciences, nanotechnology, cloud computing, data warehousing and mining, and web engineering. In addition to the above-mentioned areas of research, experimentation is also being carried out in clinical research, pharmacovigilance pharmaceutics, drug analysis, and genomics. 
      </Typography>
      <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color="white">
      Classroom teaching is supplemented by an ingenious learning system, which facilitated students to access the course content for all related subjects. MVN Society Management is committed to ensuring that no deserving student is denied education due to financial constraints. Therefore, society has launched very liberal scholarship schemes. The placement cell strives to secure relevant job opportunities for students by offering training, and skill enhancement program to make them employable. The university emphasizes upon holistic development of students through diverse extracurricular activities, workshops, and life skill development activities.

      </Typography>
    </Stack>
  </Grid>
</Grid>
)
function About() {
  return (
    <>
      <Container>
      <MUICard title={'About MVN University'} content={AboutContent()}/>
      </Container>
    </>
  );
  
}

export default About