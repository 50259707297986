 

 
import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main' 
import NewMain from '../headerone/newMain'




function TeamCrc() {

  return (
    <>
        <NewMain />
        <Bredcom title="Home" subtitle=" The Corporate Resource Centre " />


         



        <section className="about-area about-p pt-60 pb-60 p-relative fix">
        <div className="container">
        <h2 class="text-center"> Message from the Director  </h2>
        <br/> 
        </div>
            
        <div className="container">
            <div className="row" >
                <div className="col-sm-12 col-md-12 col-lg-12">
                     

                    <div class="mvn_imse_omse">
                      <h3 class="sub_title">Esteemed Partners,  </h3>
                      <p> It gives me immense pleasure to introduce you to MVN University and, more specifically, to the dynamic pool of students who are set to embark on their professional journeys. I take great pride in representing MVN Group, that has consistently nurtured, molded, and prepared students to meet the highest standards of academic requirements, not limited to its Schools but also to the the University. We are not just a group on educational institutions; we are a breeding ground for future leaders, innovators, and change-makers. </p>
                    </div> 

                    <div class="mvn_imse_omse">
                      <h3 class="sub_title">A Vision of Excellence</h3>
                      <p>At MVN University, we believe in the holistic development of every student. Education here goes beyond textbooks and classroom lectures; it extends to real-world applications and industry-relevant experiences. Our faculty, academic curriculum, and industry collaborations are all meticulously designed to ensure that our students are not just employable but are also well-rounded individuals who can contribute meaningfully to the organizations they join. <br/>
The world today is rapidly changing, and the demands from professionals have evolved significantly. Keeping this in mind, MVN University has always been ahead of the curve, updating our curriculum to match industry trends and ensuring that our students are exposed to the latest advancements in their respective fields. Whether it’s engineering, management, pharmacy, or law, our academic programs are shaped by the input of industry veterans and thought leaders to ensure that students are equipped with practical skills and the ability to think critically.
</p>
                    </div> 

                    <div class="mvn_imse_omse">
                      <h3 class="sub_title"> Why Choose MVN University Students?</h3>
                      <p> There are several reasons why I am confident that our students will add immense value to your organization. First and foremost is their adaptability. The academic environment at MVN University encourages a culture of inquiry, critical thinking, and problem-solving. We understand that the industry today requires individuals who are not just specialists but are also able to adapt to various challenges and situations. Our students have been trained in interdisciplinary approaches, making them versatile and capable of handling diverse roles within your company.<br/>
Additionally, we place a strong emphasis on soft skills. In an era where technical knowledge is accessible to many, it is communication, teamwork, leadership, and emotional intelligence that differentiate professionals. Through workshops, seminars, group discussions, and personality development programs, we ensure that our students not only have the technical know-how but also the confidence and interpersonal skills to thrive in a corporate setting.
 </p>
                    </div> 

                    <div class="mvn_imse_omse">
                      <h3 class="sub_title">Industry-Ready Through Practical Exposure </h3>
                      <p> At MVN University, we are firm believers in learning by doing. Our students are provided with numerous opportunities to engage with the industry through internships, live projects, and industrial visits. These experiences not only give them a clear understanding of how theoretical knowledge is applied in practice but also expose them to the corporate environment, helping them develop a professional demeanor early on.<br/>
Moreover, we have established strong partnerships with leading companies and organizations in various sectors. This ensures that our students have access to internships that are not only about learning but also about contributing to the company’s goals. These internships often lead to pre-placement offers, giving companies a chance to assess our students before offering them a full-time role. The feedback we receive from our industry partners is overwhelmingly positive, and many have made recurring visits to recruit more of our students year after year.
 </p>
                    </div> 

                    <div class="mvn_imse_omse">
                      <h3 class="sub_title"> Our Commitment to Diversity</h3>
                      <p>  MVN University prides itself on creating an inclusive learning environment. Our student body comes from diverse cultural, socio-economic, and geographical backgrounds, creating a melting pot of ideas, perspectives, and experiences. This diversity is one of our greatest strengths, as it fosters creativity and innovation among students. In today’s globalized world, businesses are looking for professionals who can work in diverse teams and bring varied perspectives to the table. Our students, having been part of this rich cultural exchange, are well-suited for such roles.<br/>
We also place a significant emphasis on gender diversity. We understand the importance of a balanced workforce, and through our various initiatives, we encourage female students to excel in traditionally male-dominated fields like engineering and technology. The result is a highly capable and motivated batch of students who are ready to make a significant impact in your organization, regardless of gender or background.
</p>
                    </div> 

                    <div class="mvn_imse_omse">
                      <h3 class="sub_title">Bridging the Gap Between Academia and Industry </h3>
                      <p> As part of my role as the Director of Placements, I ensure that there is a continuous dialogue between MVN University and the corporate world. We regularly invite industry experts for guest lectures, workshops, and seminars. These interactions allow students to gain insights into industry trends, challenges, and expectations, preparing them to transition smoothly from academia to the professional world.<br/>
The Corporate Resource Centre also works tirelessly to bridge the gap between academic learning and corporate demands. We conduct placement readiness workshops where students are trained in resume building, interview techniques, group discussions, and corporate etiquette. These workshops are designed in collaboration with industry professionals to ensure that students are fully prepared for the recruitment process.<br/>
Furthermore, we have introduced mock interviews, assessment centers, and aptitude tests as part of our placement preparation process. These initiatives have been incredibly successful in enhancing the employability of our students. We believe that preparation is key, and our students are rigorously trained to face challenges head-on during recruitment drives.
 </p>
                    </div> 

                    <div class="mvn_imse_omse">
                      <h3 class="sub_title">Conclusion </h3>
                      <p> In conclusion, I would like to extend a warm invitation to you to explore the vast pool of talent that our university has to offer. Our students are driven, capable, and ready to contribute to the success of your organization. With their strong academic foundations, practical exposure, and commitment to continuous learning, they are equipped to take on the challenges of the modern workplace.
I invite you to partner with MVN University in creating a brighter future, not only for our students but for your organization as well. I am confident that our collaboration will be mutually beneficial and lead to the growth and success of both our students and your esteemed company.
Thank you for considering MVN University students for your recruitment needs. We look forward to building a lasting and fruitful relationship with your organization.
 </p>
                    </div> 

                    <div class="mvn_imse_omse">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/new_images/varun_Malik_director.jpg`}  alt="img" className="vc-img" />
                      <br/><br/>
                      <p> <strong>Varun Malik</strong> </p>
                      <p> Director – Corporate Resource Centre</p>
                      <p>E-mail: varun.malik@mvn.edu.in</p>
                      <p>Mob: 8882202662</p>
                      <p><a href="https://www.linkedin.com/in/varun-malik-4a042451/ " target='_blank'>https://www.linkedin.com/in/varun-malik-4a042451/ </a></p>
                    </div> 
                



                        
            </div>
        </div>
        </div>



        <div className="container">
        <h2 class="text-center"> Team Corporate Resource Centre  </h2>
        <br/> 

       
        </div>

        <div className="container">
            <div className="row" >
                <div className="col-sm-12 col-md-12 col-lg-12"> 
                    <div class="mvn_imse_omse">
                          <div class="row">
                            <div class="col-md-3 offset-md-3">
                              <div class="one_team_member01">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/new_images/varun_mali_team01.jpg`}  alt="img" className="vc-img" />
                                    <h4>VARUN MALIK</h4>
                                    <p>Director (Corporate Resource Centre)</p>

                              </div>
                            </div>

                            <div class="col-md-3">
                              <div class="one_team_member01">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/new_images/varun_mali_team02.jpg`}  alt="img" className="vc-img" />
                                    <h4>PANKAJ BHARDWAJ</h4>
                                    <p>(Training & Placement Officer)</p>

                              </div>
                            </div>

                          </div>
                       
                    </div> 

                    
 
                      
            </div>
        </div>
        </div>




    </section>


    </>
)
}

export default TeamCrc