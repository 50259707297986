 
import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main' 
import NewMain from '../headerone/newMain'




function StudentsCentreUpcomingJobOpenings() {

    return (
        <>
            <NewMain />
            <Bredcom title="Home" subtitle="Students’ Centre - Upcoming Job Openings" />


            <section className="project-detail">
                <div className="container">

                <h2 class="text-center"> Students’ Centre - Upcoming Job Openings
                </h2>

                     
                </div>
            </section>
        </>
    )
}

export default StudentsCentreUpcomingJobOpenings