import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
import { Link } from 'react-router-dom';

function Publications() { 

    const headerMenus = [
        { mainHeader: 'School of Alied Health Sciences ', path: '/publications/school_alied_health_sciences', subHeader: [] },
        { mainHeader: 'School of Business Management and Commerce', path: '/publications/school-business-management-commerce', subHeader: [] }, 
        { mainHeader: 'School of Engineering and Technology ', path: '/publications/school-engineering-technology', subHeader: [] },
        { mainHeader: 'School of Law', path: '/publications/school-law', subHeader: [] },
        { mainHeader: 'School of Pharmaceutical Sciences', path: '/publications/school-pharmaceutical-sciences', subHeader: [] },
        { mainHeader: 'SSH', path: '/publications/ssh', subHeader: [] },
         
    ]

    const teamMembers = [
        {
            name: "Dr. Ashwani Sanghi",
            position: "Professor",
            email: "School of Allied Health Sciences",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },
        
        {
            name: "Dr. Sakshi Garg (PT)",
            position: "Assistant Professor",
            email: "SAHS/BPT",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },

        {
            name: "Dr Shishir Nigam",
            position: "Associate Professor",
            email: "BPT / SAHS",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },

        {
            name: "Shrivardhan Dheeman",
            position: "Associate Professor",
            email: "School Of Allied Health Sciences",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },

        {
            name: "Dr. Swati PT",
            position: "Assistant Professor",
            email: "School of Allied Health Sciences",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },


        {
            name: "Neeraj Bhardwaj",
            position: "Assistant Professor",
            email: "School of Allied Health Sciences ",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },

        {
            name: "Himani  ",
            position: "Assistant Professor",
            email: "SAHS/BPT",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },

        {
            name: "Ritika Jaggi",
            position: "Assistant Professor",
            email: "School of Allied Health Sciences ",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },

        {
            name: "Dr. Vineet Kumar ",
            position: "Dean, School of Allied Health Sciences",
            email: "School of Allied Health Science ",
            image: "/assets/img/new_images/faculty/demo_user.jpg",
            path: "/publications/school_alied_health_sciences/dr-ashwani-sanghi"
        },

         
    ];

  return (
    <>
      <NewMain />
      <div className='new_pub_sub_menu'>
      <WidgetsMenu headerMenus={headerMenus}/>
      </div>
            <div className='container_custom'>
                    <div className='short_banner'>
                        <img src={`${process.env.PUBLIC_URL}/assets/img/SAHS/sahs.jpg`} />
                    </div>

            </div>


            <div className='container_custom'>
                    <div className='Faculties_page'>
                            <h3 class="title">School of Allied Health Sciences Faculties</h3>


                            <div class="teamlist">
                                    <div className='row'>
                                          

                                        {teamMembers.map((member, index) => (
                                            <div className='col-md-4' key={index}>
                                                <div className='one_team_member'>
                                                    <div className='teamimage'>
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}${member.image}`}
                                                            className='MuiAvatar-img css-1hy9t21'
                                                            alt={member.name}
                                                        />
                                                    </div>
                                                    <div className='team_content'>
                                                        <p><strong>{member.name}</strong></p>
                                                        <p>{member.position}</p>
                                                        <p>{member.email}</p>
                                                        <Link to={member.path} className='btn_view'>View Detail</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>



                            </div>

                    </div>

            </div>





    </>
  )
}

export default Publications