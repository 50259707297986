import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
import { Grid } from "@mui/material";

function PublicationsFacultyDetail() { 

    const headerMenus = [
        { mainHeader: 'School of Alied Health Sciences ', path: '/publications/school_alied_health_sciences', subHeader: [] },
        { mainHeader: 'School of Business Management and Commerce', path: '/publications/school-business-management-commerce', subHeader: [] }, 
        { mainHeader: 'School of Engineering and Technology ', path: '/publications/school-engineering-technology', subHeader: [] },
        { mainHeader: 'School of Law', path: '/publications/school-law', subHeader: [] },
        { mainHeader: 'School of Pharmaceutical Sciences', path: '/publications/school-pharmaceutical-sciences', subHeader: [] },
        { mainHeader: 'SSH', path: '/publications/ssh', subHeader: [] },
         
    ]


    

  return (
    <>
      <NewMain />
      <div className='new_pub_sub_menu'>
      <WidgetsMenu headerMenus={headerMenus}/>
      </div>
    
      <Grid container spacing={2} style={{ margin: "10px 0px" }}>
    
    <div class="container">
      <div class="team-single new_signel_publiation_details">

      <div class="row justify-content-center">
          <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
            <div class="team-single-img" style={{textAlign:'center'}}>
              <img src="/assets/img/new_images/faculty/demo_user.jpg" alt="" />
            </div>
            <div class="bg-light-gray padding-30px-all md-padding-25px-all sm-padding-20px-all text-center">
              <h2>Dr. Ashwani Sanghi</h2>
              <span>  Professor at School of Allied Health Sciences</span>
              <div class="margin-20px-top team-single-icons">
                
              </div>
            </div>
          </div>
          </div>


        <div class="row ">
           

          <div class="col-lg-12 col-md-12">
              <div class="team-single-text padding-50px-left sm-no-padding-left">
                <div class="contact-info-section margin-40px-tb">
                  <ul class="list-style9 no-margin">
                    <li>
                      <div class="row">
                        <div class="col-md-12 col-12">
                          <i class="far fa-newspaper text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                                List of Publications:
                          </strong>
                        </div>
                        <div class="col-md-12 col-12">
                            <h4>  Research Contributions from 2019- 2024 (accepted/published and under publication)</h4>
                        <table border="1" cellpadding="10" cellspacing="0"  >
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Authors</th>
                                    <th>Title</th>
                                    <th>Conference/Book/Journal</th>
                                    <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>1</td>
                                    <td>Bhatt SS, Vamshi M, Singh V, Rani P, Dayal D, Gupta A, Sanghi AK, Agrawal S, Wahi N, Chaubey KK</td>
                                    <td>Ameriolations with VAM and PSB Inoculation of Growth and Yield Attributes in Pea (Pisum sativum L.) cv. Arkel</td>
                                    <td>Legume Research</td>
                                    <td>Published (2024)</td>
                                    </tr>
                                    <tr>
                                    <td>2</td>
                                    <td>Mokhria RK, Bhardwaj JK, Sanghi AK</td>
                                    <td>History, origin, transmission, genome structure, replication, epidemiology, pathogenesis, clinical features, diagnosis, and treatment of COVID-19: A review</td>
                                    <td>World Journal of Meta-Analysis</td>
                                    <td>Published (2023)</td>
                                    </tr>
                                    <tr>
                                    <td>3</td>
                                    <td>Dabas L, Sanghi AK</td>
                                    <td>A Review on Biofuels and Chemicals Production by Co-pyrolysis of Solid Biomass Feedstocks and Non-degradable Plastics</td>
                                    <td>International Journal for Multidisciplinary Research</td>
                                    <td>Published (2023)</td>
                                    </tr>
                                </tbody>
                                </table>
                           
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-12 col-12">
                          <i class="far fa-book text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                          Book Chapter, Book Publications and Patents (2019-24):
                          </strong>
                        </div>
                        <div class="col-md-12 col-12">
                        <h4>   </h4>
                        <table border="1" cellpadding="10" cellspacing="0"  >
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Authors</th>
                                    <th>Title</th>
                                    <th>Conference/Book/Journal</th>
                                    <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>1</td>
                                    <td>Dixit V, Goyal U, Kamal WJ, Chaubey KK, Manjunath BT, Xavier J, Pal AK, Verma DK, and Sanghi AK</td>
                                    <td>Assessing the Impact of Silver and Zinc on Soil Microbial Structure and Functionality</td>
                                    <td>Book Chapter (Springer)</td>
                                    <td>Published (2024)</td>
                                    </tr>
                                </tbody>
                                </table>

                         
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-12 col-12">
                          <i class="far fa-file text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                          Book Publications (2019-24)
                          </strong>
                        </div>
                        <div class="col-md-12 col-12">
                        <h4>   </h4>
                        <table border="1" cellpadding="10" cellspacing="0"  >
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Authors</th>
                                    <th>Title</th>
                                    <th>Conference/Book/Journal</th>
                                    <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>1</td>
                                    <td>Dixit V, Goyal U, Kamal WJ, Chaubey KK, Manjunath BT, Xavier J, Pal AK, Verma DK, and Sanghi AK</td>
                                    <td>Assessing the Impact of Silver and Zinc on Soil Microbial Structure and Functionality</td>
                                    <td>Book Chapter (Springer)</td>
                                    <td>Published (2024)</td>
                                    </tr>
                                </tbody>
                                </table>
                         
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-12 col-12">
                          <i class="far fa-id-badge text-blue"></i>
                          <strong class="margin-10px-left text-blue">
                          Patent Publications 
                          </strong>
                        </div>
                        <div class="col-md-12 col-12">
                        <h4> Research Contributions for 2019-24 (accepted/published and under publication)</h4>

                        <table border="1" cellpadding="10" cellspacing="0" >
  <thead>
    <tr>
      <th>#</th>
      <th>Authors</th>
      <th>Title</th>
      <th>Indian/International</th>
      <th>Published/Granted</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Kumar D, Parcha V, Sanghi AK, Arora S</td>
      <td>HERBAL COMPOSITION HAVING ANTI-HYPERLIPIDEMIC ACTIVITY AND METHOD FOR PREPARATION THEREOF</td>
      <td>Indian</td>
      <td>Published (2023)</td>
    </tr>
  </tbody>
</table>

                        
                        </div>
                      </div>
                    </li>
                    
                  </ul>
                </div>

                
              </div>
            </div>


 

          <div class="col-md-12"></div>
        </div>
      </div>
    </div>
  </Grid>


    </>
  )
}

export default PublicationsFacultyDetail