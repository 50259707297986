import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main' 
import NewMain from '../headerone/newMain'



function TheCorporateResourceCentre() {

    return (
        <>
            <NewMain />
            <Bredcom title="Home" subtitle=" The Corporate Resource Centre " />


             



            <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="container">
            <h2 class="text-center"> Vison & Mission  </h2>
            <br/> 
            </div>
                
            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                         

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">Vision:  </h3>
                          <p> To be a leading facilitator of career success, promoting core values and creating industry-ready graduates who excel in a dynamic corporate world by striving to create a collaborative ecosystem that fosters innovation, leadership, and lifelong success. </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> Mission: </h3>
                          <p> To empower students of MVN University with the skills, knowledge, and opportunities necessary to secure fulfilling and successful careers. CRC strives to build strong partnerships with leading organizations, providing students with access to top-tier job opportunities, internships, and professional development resources. Through personalized guidance, industry engagement, and continuous support, CRC aims to bridge the gap between academia and the professional world, ensuring that our graduates are not only employable but also future-ready leaders.</p>
                        </div> 

                            
                </div>
            </div>
            </div>



            <div className="container">
            <h2 class="text-center"> Placement Policy  </h2>
            <br/> 

           
            </div>

            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                    <h2 class="text-center">Eligibility for Campus Placements
                    </h2>

                        <div class="mvn_imse_omse">
                          
                          <p> Only the following students shall be considered eligible for placements:</p>

                          <ul>
                            <li>a.	Students with academic credentials > 50% till the pre-final year.</li>
                            <li>b.	Students fulfilling minimum attendance criteria as declared and updated by the Examinations department time to time.</li>
                            <li>c.	Students never reported for any indisciplinary action.</li>
                            <li>d.	Students with only one or two backlogs till pre-final year.</li>
                           
                          </ul>
                           
                        </div> 

                         

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">CRC-Industry interaction </h3>
                          <p>  Time to time, the final year students will have the opportunity to interact with Industry professionals through means of Webinars, Seminars, and Pre-Placement Drives and Campus Drives. Students are prohibited from contacting Company/HR Delegates directly for any reason. If they encounter any issues, they must first reach out to their Placement Officer (CRC) and discuss the problem with them. </p>
                          


                          
                        </div> 


                        <div class="mvn_imse_omse"> 
                        <h3 class="sub_title">One Student, One Job Policy  </h3>
                          
                          <p>  Placement opportunities are made available to all students who are eligible and registered with CRC, adhering strictly to the "One Student, One Job" policy. Once a student is selected by a company, they will not be allowed to participate in any other campus drives organized by the University. The goal is to ensure that every student has an equal opportunity for employment, preventing a few students from monopolizing all the job offers. <br/>

If a student has been recruited by a company, they will not be permitted to participate in any other placement interviews arranged by CRC. However, placed students may be allowed to attend other drives if the visiting companies offer higher package or are from a core stream company.

 </p>
                          
                          
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">Discipline during placement process </h3>
                          <p> Behavior – Every student must maintain strict discipline throughout the recruitment process. Any student found misbehaving or engaging in misconduct will be barred from participating in the entire placement season. <br/>

Language – Language plays a very crucial role, during the interviews. Casual talks with other participants or remarks directed at company delegates, both during and after the process, are prohibited.<br/>

Dress Code– Students must attend the placement process in full dress code, as specified by the University. Student not complying with Dress code shall not be allowed to sit for the placement.
<br/>
Attendance – Students are required to participate in all selection rounds conducted by the companies they have registered for, including PPT, online tests, GDs, and interviews. 
  </p>
  <br/><br/>
  <p><strong>Failure to comply with these requirements will result in strict action by the college authorities.</strong></p>
                           
                        </div> 

                    


                        <div class="mvn_imse_omse">
                        <h2 class="text-center">Placement Process</h2>
 
                        </div> 


                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">  1.	Consent for Placements </h3>
                          <p>  In the very beginning of the last and second last semester, students are required to give consent whether they are seeking placement or not. The form shall be shared with them as soon as the academic session begins. This is only for final year students.  </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">  2.	Eligibility for Campus Placements </h3>
                          <p>  After the students have registered for Campus Placements, the CRC will check their eligibility, as per Placemen Policy.  </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 3.	Job Application  </h3>
                          <p>  Time to time, CRC will keep sharing Placement Notices, through proper channel. The students are required to apply to the opportunities before given deadline.  </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 4.	Attending Interview  </h3>
                          <p>  After application, on shortlisting with the company, the CRC will roll out Interview schedules with the students. The students are expected to be in proper attire and attend the entire interview process with discipline.  </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">5.	Selection   </h3>
                          <p>  Aceing a company’s interview process will lead to selection in the company.  </p>
                        </div> 

                        <div class="mvn_imse_omse">
                          <h3 class="sub_title"> 6.	Joining  </h3>
                          <p>  The joining schedule is entirely at the discretion of the company and dependent on market conditions. All students must join on time as per the company's communication but must complete all requirements of program of study. <br /> <br /> Companies may specify early joining dates in their offer letters in consultation with Corporate Resource Centre. Such instances must be reported to the CRC, placement office, HOD, and Dean.
  </p>
                        </div> 
                        
                        <div class="mvn_imse_omse">
                          <h3 class="sub_title">  7.	Offer Letter submission </h3>
                          <p> Soon after the placement drive concludes, companies share the list of selected students with CRC. All selected students will be notified by CRC through email.<br /> <br />
Selected students must submit their Offer Letter or initial selection email from the company to CRC on placements@mvn.edu.in.
   </p>
                        </div> 

 

                          
                </div>
            </div>
            </div>




        </section>


        </>
    )
}

export default TheCorporateResourceCentre