import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function ResearchDevelopmentHome() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Research and Development Cell" /> 
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>PhD Program:</h3>
                                </div>
                                <p>
                                Our PhD program at MVN University is designed to offer students rigorous training experience, personalized mentorship, and access to cutting-edge research facilities. Regardless of your field of interest, whether it's biomedical sciences, engineering, social sciences, or any other discipline, our multidisciplinary approach ensures that you receive comprehensive training tailored to your research interests. As a PhD student at MVN University, you'll have the opportunity to work closely with world-class faculty members who are leaders in their respective fields. You'll be engaged in hands-on research, which will give you the experience and skills needed to excel in your future career.
                                </p>
 


                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Postdoctoral Program</h3>
                                </div>
                                <p>
                                Our Postdoctoral Research Program at MVN University provides recent PhD graduates with the opportunity to collaborate with leading researchers, pursue their research interests, and develop new skills. We offer a competitive salary and benefits package, cutting-edge research facilities, and support for academic research, industry partnerships, and entrepreneurial ventures. We encourage applications from individuals of all backgrounds and are committed to promoting diversity, equity, and inclusion in the research community. Please visit our website to learn more about our programs and apply.
                                </p>
 


                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>ABOUT Research and Development Cell</h3>
                                </div>
                                <p>
                                The Research and Development Cell (RDC) aims to foster a conducive environment for enhanced research productivity. The objectives of establishing the Research and Development Cell (RDC) are to encourage a research ecosystem for reliable, impactful, and sustained research output in the University and to promote new ideas, discoveries, and innovations to augment university growth. RDC will also monitor the progress of research activities in the university at various levels and encourage funding for research projects from various government and non-government funding agencies/bodies. It will persistently work to develop facilities for a holistic research environment in the university. The essential elements of such an ecosystem, viz., generation of knowledge and facilitation of research, innovation, and technology development for industrial & societal benefits, are addressed by human resource (researcher & faculty), intellectual capital (knowledge & skills), governance (regulation & policies) and financial resources (funding & grants).
                                </p>
 


                            </div>
                        </div>
                    </div>



                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Vision</h3>
                                </div>
                                 <ul class="details_list">
                                    <li> To create a robust mechanism of developing and strengthening the research ecosystem in MVN University</li>
                                    <li> Envisaged and aligned within Higher Education Institutions, and provisions of NEP-2020.</li>
                                    
                                 </ul>


                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Mission</h3>
                                </div>
                                 <ul class="details_list"> 
                                    <li> To set up a competitive environment for augmenting research and innovations.</li>
                                    <li>  To foster national and international collaborations across industry, government, community- based organizations and agencies.</li>
                                    <li>  To expedite conducive research through promoting extramural and intramural funding benefits.</li>
                                    
                                 </ul>


                            </div>
                        </div>
                    </div>





                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Organizational Structure of Research and Development Cell</h3>
                                </div>
                                <p>
                                At MVN, the Research & Development Cell has been continuously striving for a research and innovation culture in the University. The organizational structure of the R&D Cell is constituted as per the guidelines issued by UGC 2022
                                </p>

                                <img src="/assets/img/organizational_structure.jpg" alt="contact-bg-an-01" />

 


                            </div>
                        </div>
                    </div>

                    <br/> 


                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Committees and their members within R&D Cell</h3>
                                </div>
                                 <table class="table table-bordered">
                                 <thead class="table-light head_red">
                                        <tr>
                                            <th scope="col">Committee Name</th>
                                            <th scope="col">Committee Members</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                    

                                        <tr>
                                            <td>Finance and Infrastructure</td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>Research Program, Policy Development</td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>Collaboration and community</td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>Product Development, Monitoring, and commercialization</td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>IPR, Legal and Ethical Matters</td>
                                            <td> </td>
                                        </tr>
                                        
                                        </tbody>

                                 </table>
 


                            </div>
                        </div>
                    </div>
                    <br/> 

                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Research Advisory Council</h3>
                                </div>
                                <p>
                                The Research Advisory Council (RAC) is the principal research body of the University. It provides a larger holistic vision of the research to be undertaken by the University, including prioritization of the research areas. The RAC also supervises, directs, and monitors all the University's research activities and Ph.D. programs.
                                </p>
 


                            </div>
                        </div>
                    </div>

                    <br/> 
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Constitution of Research Advisory Council</h3>
                                </div>

                                <table class="table table-bordered">
                                    <thead class="table-light head_red">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Constituent</th> 
                                            <th scope="col">Designation</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 

                                        <tr>
                                            <td>1</td>
                                            <td> Vice Chancellor </td>
                                            <td> Chairperson </td>
                                        </tr>

                                        <tr>
                                            <td>2</td>
                                            <td> Dean, Academics </td>
                                            <td>  Member </td>
                                        </tr>

                                        <tr>
                                            <td>3. </td>
                                            <td> Dean, School of Engineering and Technology </td>
                                            <td> Member  </td>

                                        </tr>

                                        <tr>
                                            <td>4.</td>
                                            <td> Dean, School of Business Management and Commerce </td>
                                            <td> Member  </td>

                                        </tr>

                                        <tr>
                                            <td>1</td>
                                            <td> Vice Chancellor </td>
                                            <td>  Member </td>

                                        </tr>

                                        <tr>
                                            <td>5. </td>
                                            <td> Dean, School of Pharmaceutical Sciences </td>
                                            <td> Member  </td>

                                        </tr>

                                        <tr>
                                            <td>6. </td>
                                            <td> Dean, School of Law </td>
                                            <td>  Member </td>

                                        </tr>

                                        <tr>
                                            <td>7. </td>
                                            <td> Dean, School of Allied Health Sciences </td>
                                            <td> Member  </td>

                                        </tr>

                                        <tr>
                                            <td>8. </td>
                                            <td> Dean, School of Humanities and Basic Sciences </td>
                                            <td> Member  </td>

                                        </tr>


                                        <tr>
                                            <td>9. </td>
                                            <td> Director, IQAC </td>
                                            <td> Member  </td>

                                        </tr>


                                        <tr>
                                            <td>10. </td>
                                            <td> Outside members<br/>
                                                    1. ______ <br/>
                                                    2. ______ <br/>
                                                    3. ______ <br/> 
                                            </td>
                                            <td> Member  </td>

                                        </tr>


                                        <tr>
                                            <td>11. </td>
                                            <td> Director, Research and Development Cell </td>
                                            <td> Member Coordinator  </td>

                                        </tr>

                                        </tbody> 
 


                                 </table>   


                            </div>
                        </div>
                    </div>

                    <br/> 


                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Ph.D. Students: List of Ph.D. Students</h3>
                                </div>

                                <table class="table table-bordered">
                                    <thead class="table-light head_red">
                                        <tr>
                                            <th scope="col">S.N.</th>
                                            <th scope="col">School</th> 
                                            <th scope="col">BATCH</th> 
                                            <th scope="col">Student Name</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
 
                                            <tr>
                                                <td>1</td>
                                                <td>SOL</td>
                                                <td>2017-18</td>
                                                <td>Brajendra Singh Kushwaha</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>SOL</td>
                                                <td>2017-18</td>
                                                <td>Chandranshu Pateria</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>SOL</td>
                                                <td>2017-18</td>
                                                <td>Laxhman Rohit Dubey</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOL</td>
                                                <td>2017-18</td>
                                                <td>Sapna Verma</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>SOET</td>
                                                <td>2017-18</td>
                                                <td>Mamta Saxena</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>SOPS</td>
                                                <td>2018-19</td>
                                                <td>Nikhleshwar Kumar Rana</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>SOET</td>
                                                <td>2019-20</td>
                                                <td>Babita Yadav</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>SOL</td>
                                                <td>2019-20</td>
                                                <td>Monika Chawla</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>SOL</td>
                                                <td>2019-20</td>
                                                <td>Ravi Chadha</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>SOL</td>
                                                <td>2019-20</td>
                                                <td>Sanjay Aggarwal</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>SOL</td>
                                                <td>2019-20</td>
                                                <td>Varun Kalra</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>SOL</td>
                                                <td>2019-20</td>
                                                <td>Ishwar Kumari</td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>SOPS</td>
                                                <td>2019-20</td>
                                                <td>Km. Swati Srivastava</td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>SOPS</td>
                                                <td>2019-20</td>
                                                <td>Vijeta Gupta</td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td>SOL</td>
                                                <td>2019-20</td>
                                                <td>Ritu</td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td>SOL</td>
                                                <td>2019-20</td>
                                                <td>Yash Pal Sharma</td>
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td>SOL</td>
                                                <td>2019-20</td>
                                                <td>Dinesh Mohan Sinha</td>
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td>SOET</td>
                                                <td>2020-21</td>
                                                <td>Poonam</td>
                                            </tr>
                                            <tr>
                                                <td>19</td>
                                                <td>SOET</td>
                                                <td>2020-21</td>
                                                <td>Atul Rana</td>
                                            </tr>
                                            <tr>
                                                <td>20</td>
                                                <td>SOET</td>
                                                <td>2020-21</td>
                                                <td>Sachin Garg</td>
                                            </tr>
                                            <tr>
                                                <td>21</td>
                                                <td>SOET</td>
                                                <td>2020-21</td>
                                                <td>Sudha Rani</td>
                                            </tr>
                                            <tr>
                                                <td>22</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Rachna</td>
                                            </tr>
                                            <tr>
                                                <td>23</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Karuna</td>
                                            </tr>
                                            <tr>
                                                <td>24</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Sanjeev Tiwari</td>
                                            </tr>
                                            <tr>
                                                <td>25</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Devesh Grover</td>
                                            </tr>
                                            <tr>
                                                <td>26</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Surbhi Bhardwaj</td>
                                            </tr>
                                            <tr>
                                                <td>27</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Nand Kishore Aggarwal</td>
                                            </tr>
                                            <tr>
                                                <td>28</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Parvinder</td>
                                            </tr>
                                            <tr>
                                                <td>29</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Rama</td>
                                            </tr>
                                            <tr>
                                                <td>30</td>
                                                <td>SOL</td>
                                                <td>2020-21</td>
                                                <td>Rachna / W21</td>
                                            </tr>
                                            <tr>
                                                <td>31</td>
                                                <td>SOPS</td>
                                                <td>2020-21</td>
                                                <td>Mahesh Kumar</td>
                                            </tr>
                                            <tr>
                                                <td>32</td>
                                                <td>SSH</td>
                                                <td>2020-21</td>
                                                <td>Ram Kumar</td>
                                            </tr>
                                            <tr>
                                                <td>33</td>
                                                <td>SSH</td>
                                                <td>2020-21</td>
                                                <td>Mamta Sharma</td>
                                            </tr>
                                            <tr>
                                                <td>34</td>
                                                <td>SSH</td>
                                                <td>2020-21</td>
                                                <td>Sangita Sheron</td>
                                            </tr>
                                            <tr>
                                                <td>35</td>
                                                <td>SAHS</td>
                                                <td>2020-21</td>
                                                <td>Mahesh Singh Danu</td>
                                            </tr>
                                            <tr>
                                                <td>36</td>
                                                <td>SSH</td>
                                                <td>2020-21</td>
                                                <td>Yogesh</td>
                                            </tr>
                                            <tr>
                                                <td>37</td>
                                                <td>SOET</td>
                                                <td>2021-22</td>
                                                <td>Sudha</td>
                                            </tr>
                                            <tr>
                                                <td>38</td>
                                                <td>SOET</td>
                                                <td>2021-22</td>
                                                <td>Amrita Koul</td>
                                            </tr>
                                            <tr>
                                                <td>39</td>
                                                <td>SOET</td>
                                                <td>2021-22</td>
                                                <td>Neetu Singh</td>
                                            </tr>
                                            <tr>
                                                <td>40</td>
                                                <td>SOL</td>
                                                <td>2021-22</td>
                                                <td>Prince Kumar</td>
                                            </tr>
                                            <tr>
                                                <td>41</td>
                                                <td>SOL</td>
                                                <td>2021-22</td>
                                                <td>Ram Kumar</td>
                                            </tr>
                                            <tr>
                                                <td>42</td>
                                                <td>SOL</td>
                                                <td>2021-22</td>
                                                <td>Chanchal Vashishtha</td>
                                            </tr>
                                            <tr>
                                                <td>43</td>
                                                <td>SOL</td>
                                                <td>2021-22</td>
                                                <td>Sushil Kumar</td>
                                            </tr>
                                            <tr>
                                                <td>44</td>
                                                <td>SOPS</td>
                                                <td>2021-22</td>
                                                <td>Bhawna Goel</td>
                                            </tr>
                                            <tr>
                                                <td>45</td>
                                                <td>SOPS</td>
                                                <td>2021-22</td>
                                                <td>Swati Dubey</td>
                                            </tr>
                                            <tr>
                                                <td>46</td>
                                                <td>SOET</td>
                                                <td>2021-22</td>
                                                <td>Amresh Kumar</td>
                                            </tr>
                                            <tr>
                                                <td>47</td>
                                                <td>SSH</td>
                                                <td>2021-22</td>
                                                <td>Vinnay Banga</td>
                                            </tr>
                                            <tr>
                                                <td>48</td>
                                                <td>SOET</td>
                                                <td>2022-23</td>
                                                <td>Anita</td>
                                            </tr>
                                            <tr>
                                                <td>49</td>
                                                <td>SOET</td>
                                                <td>2022-23</td>
                                                <td>Awnindra Dautt Tiwari</td>
                                            </tr>
                                            <tr>
                                                <td>50</td>
                                                <td>SOET</td>
                                                <td>2022-23</td>
                                                <td>Vishal Singh</td>
                                            </tr>
                                            <tr>
                                                <td>51</td>
                                                <td>SOET</td>
                                                <td>2022-23</td>
                                                <td>Vibhore Goel</td>
                                            </tr>
                                            <tr>
                                                <td>52</td>
                                                <td>SOET</td>
                                                <td>2022-23</td>
                                                <td>Ms Ruchika Gupta</td>
                                            </tr>
                                            <tr>
                                                <td>53</td>
                                                <td>SOET</td>
                                                <td>2022-23</td>
                                                <td>Ms Suman Devi</td>
                                            </tr>
                                            <tr>
                                                <td>54</td>
                                                <td>SOET</td>
                                                <td>2022-23</td>
                                                <td>Deepika Yadav</td>
                                            </tr>
                                            <tr>
                                                <td>55</td>
                                                <td>SSH</td>
                                                <td>2022-23</td>
                                                <td>Seema Sharma</td>
                                            </tr>
                                            <tr>
                                                <td>56</td>
                                                <td>SAHS</td>
                                                <td>2022-23</td>
                                                <td>Priya</td>
                                            </tr>
                                            <tr>
                                                <td>57</td>
                                                <td>SOET</td>
                                                <td>2023-24</td>
                                                <td>Rachana Srivastava</td>
                                            </tr>
                                            <tr>
                                                <td>58</td>
                                                <td>SOL</td>
                                                <td>2023-24</td>
                                                <td>Kanchan</td>
                                            </tr>
                                            <tr>
                                                <td>59</td>
                                                <td>SOET</td>
                                                <td>2023-24</td>
                                                <td>Munesh</td>
                                            </tr>
                                            <tr>
                                                <td>60</td>
                                                <td>SOL</td>
                                                <td>2023-24</td>
                                                <td>Ruchi Sharma</td>
                                            </tr>
                                            <tr>
                                                <td>61</td>
                                                <td>SOET</td>
                                                <td>2023-24</td>
                                                <td>Poonam</td>
                                            </tr>
                                            <tr>
                                                <td>62</td>
                                                <td>SOL</td>
                                                <td>2023-24</td>
                                                <td>Ragini Agarwal</td>
                                            </tr>
                                            <tr>
                                                <td>63</td>
                                                <td>SOET</td>
                                                <td>2023-24</td>
                                                <td>Leena Rani</td>
                                            </tr>
                                            <tr>
                                                <td>64</td>
                                                <td>SOL</td>
                                                <td>2022-23</td>
                                                <td>Neeraj Mehta</td>
                                            </tr>
                                            <tr>
                                                <td>65</td>
                                                <td>SOL</td>
                                                <td>2022-23</td>
                                                <td>Vineet Chauhan</td>
                                            </tr>
                                            <tr>
                                                <td>66</td>
                                                <td>SOPS</td>
                                                <td>2022-23</td>
                                                <td>Neha Bhamboo</td>
                                            </tr>
                                            <tr>
                                                <td>67</td>
                                                <td>SOPS</td>
                                                <td>2023-24</td>
                                                <td>Shivani</td>
                                            </tr>
                                            <tr>
                                                <td>68</td>
                                                <td>SAHS</td>
                                                <td>2023-24</td>
                                                <td>Cheenu</td>
                                            </tr>
                                            <tr>
                                                <td>69</td>
                                                <td>SAHS</td>
                                                <td>2023-24</td>
                                                <td>Shivani Shokhanda</td>
                                            </tr>
                                            <tr>
                                                <td>70</td>
                                                <td>SOPS</td>
                                                <td>2023-24</td>
                                                <td>Rajeshwar Verma</td>
                                            </tr>
                                            <tr>
                                                <td>71</td>
                                                <td>SOPS</td>
                                                <td>2023-24</td>
                                                <td>Prabha Singh</td>
                                            </tr>
                                            <tr>
                                                <td>72</td>
                                                <td>SOPS</td>
                                                <td>2023-24</td>
                                                <td>Aashish Bharadwaj</td>
                                            </tr>
                                            <tr>
                                                <td>73</td>
                                                <td>SOPS</td>
                                                <td>2023-24</td>
                                                <td>Anshuman Koul</td>
                                            </tr>


 
                                        </tbody> 
 


                                 </table>   


                            </div>
                        </div>
                    </div>


 

                    <br/> 
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Research Ordinance (Important Forms)</h3>
                                </div>

                                <table class="table table-bordered">
                                    <thead class="table-light head_red">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Document</th> 
                                            <th scope="col">Link to Download</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 

                                        <tr>
                                            <td>1</td>
                                            <td> Synopsis Format </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/MVN-synopsis-guidelines.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>2</td>
                                            <td> Progress Report </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Progress-Report-Format-for-Research-Scholar.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>3</td>
                                            <td> Research Proposal </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Research-proposal-format-for-MVNU_new.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>4</td>
                                            <td> Submission Report of Ph.D. </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Submission-report-of-PhD-document.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>5</td>
                                            <td> Font Page </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Department-details-for-front-page-in-synopsis-and-thesis.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>6</td>
                                            <td> Ph.D. Thesis submission Guidelines </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/PhD-Thesis-Submission-Guidelines-1-3-22.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>7</td>
                                            <td> Compliance Report </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Compliance-Report.docx`} download >Download </a> </td>
                                        </tr>


                                        <tr>
                                            <td>8</td>
                                            <td> Title Change Application </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Title Change Application.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>9</td>
                                            <td> Application for Extension </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Application-for-Extension.docx`} download >Download </a> </td>
                                        </tr>


                                        <tr>
                                            <td>10</td>
                                            <td> Annexure 1 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-1-Cover-page-NEW.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>11</td>
                                            <td> Annexure 2	 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-2-Certificate-by-Supervisor.docx`} download >Download </a> </td>
                                        </tr>


                                        <tr>
                                            <td>12</td>
                                            <td> Annexure 3 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure 3 Declaration by Scholar.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>13</td>
                                            <td> Annexure 4 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-4-Certificate-of-Plagiarism-check.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>14</td>
                                            <td> Annexure 5 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-5-Publication-Details.docx`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>15</td>
                                            <td> Annexure 6</td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-6-Conference-Details.docx`} download >Download </a> </td>
                                        </tr>


                                        <tr>
                                            <td>16</td>
                                            <td> Annexure 7 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Annexure-7-No-due-Certificate.docx`} download >Download </a> </td>
                                        </tr>


                                       

                                         

                                        </tbody> 
 


                                 </table>   


                            </div>
                        </div>
                    </div>

                    <br/> 



                    <br/> 
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                            <div className="about-title second-title ">                                    
                                    <h3>Research at MVN</h3>
                                </div>

                                <video width="600" controls>
                                    <source src={`${process.env.PUBLIC_URL}/rdct_smoe.mp4`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>


                            </div>
                        </div>
                    </div>

                    <br/> 





 
 


                </div>
            </section>

    </>
  )
}

export default ResearchDevelopmentHome