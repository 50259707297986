  
import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main' 
import NewMain from '../headerone/newMain'



function CorporateLaison() {

    return (
        <>
            <NewMain />
            <Bredcom title="Home" subtitle=" 	Corporate Laison " />


            <section className="project-detail">
                <div className="container">

                <h2 class="text-center">  	Corporate Laison 
                </h2>

                     
                </div>
            </section>
        </>
    )
}

export default CorporateLaison