import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function WorkingPaperSeries() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Publications" /> 
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                     

 

                    <br/> 
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>Working paper series</h3>
                                </div>

                                <table class="table table-bordered">
                                    <thead class="table-light head_red">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Document</th> 
                                            <th scope="col">Link to Download</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 

                                        <tr>
                                            <td>1</td>
                                            <td> Working Paper-1 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Working Research Paper 1.pdf`} download >Download </a> </td>
                                        </tr>

                                        <tr>
                                            <td>2</td>
                                            <td> Working Paper-2 </td>
                                            <td> <a href={`${process.env.PUBLIC_URL}/assets/Working Research Paper 2.pdf`} download >Download </a> </td>
                                        </tr>
 
                                       

                                         

                                        </tbody> 
 


                                 </table>   


                            </div>
                        </div>
                    </div>

                    <br/> 



 
 


                </div>
            </section>

    </>
  )
}

export default WorkingPaperSeries