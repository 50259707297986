import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../headerone/Main'
import { Link } from 'react-router-dom'
import First from './first'
import NewMain from '../../headerone/newMain'
function NationalAcademicDepository() {
 

  return (
    <>
      <NewMain />
      <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  {/* <h2> {image?"":subtitle} </h2> */}
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Home </Link>
                  </li> 
                  <li className="breadcrumb-item">
                    <Link to="/re-evalution-rules">Examination </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="container">
            <h2 class="text-center">  National Academic Depository   </h2>
            <br/> 
            </div>
              
            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="text-justify">
                        MVN University has created a database consisting of all academic documents of diploma and degree programs passed by the students from MVN University on National Academic depository portal. The Portal creates ID of students which is linked with Digilocker. Students can access their academic documents by using their registration ID and Password.
                        </p>
                        
                </div>
            </div>
            </div>
        </section>
    </>
  )
}

export default NationalAcademicDepository